import { Component, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { translate } from '@pipes/transalte.pipe';
import { LanguageService } from '@services/language.service';
import { HeaderComponent } from '../header/header.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.css',
  imports: [translate, HeaderComponent, RouterLink],
})
export class PageNotFoundComponent {
  languageService = inject(LanguageService);
  isMobile: boolean = false;
  isDesktopDevice: boolean = false;

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Tablet])
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = true;
          this.isDesktopDevice = false;
        }
      });
    breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = false;
          this.isDesktopDevice = true;
        }
      });
  }
}
