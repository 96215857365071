// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyB8yA6ViZGFHGXuC2hLjqGIgH88IW1w0UY",
    authDomain: "tablesolutions-174b4.firebaseapp.com",
    databaseURL: "https://tablesolutions-174b4.firebaseio.com",
    projectId: "tablesolutions-174b4",
    storageBucket: "tablesolutions-174b4.appspot.com",
    messagingSenderId: "958579211150",
    appId: "1:958579211150:web:286a10928ba6b1082682df",
    measurementId: "G-MW8BHFMMNQ",
    locationId :"europe-west"
  },
  vapidKey: "BIkQ7COs8WqtoigCnCr4a6Wm9GC4NDY3vTutBLKFj_HWamK1tv-3EgdvtgqpZ03EW9K2wTO0Fs9VPVPRanU_QfY"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
