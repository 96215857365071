@if (!isMobile) {
    <app-header></app-header>
    }
  
  <div class="section-3">
    <div class="container">
      <h3 class="heading-3">
        {{ "Page non trouvée" | translate : languageService.language() : "Page not found" }}
      </h3>
  
      <div class="mt-4">
        <button class="sendBtn" routerLink="/home">{{ "Page d'accueil" | translate : languageService.language() : "Homepage" }}</button>
      </div>
    </div>
  </div>
  