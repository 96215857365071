import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isNewVersionAvailable: boolean = false;

  constructor(private readonly swUpdate: SwUpdate) {
    if (typeof navigator !== 'undefined') {
      const isInWebAppiOS =
        'standalone' in window.navigator && window.navigator['standalone'];

      let anotherPWA = false;

      if (window.matchMedia('(display-mode : standalone)').matches) {
        anotherPWA = true;
      }

      if (isInWebAppiOS === true || anotherPWA === true) {
        if (!this.swUpdate.isEnabled) {
          return;
        }

        this.swUpdate.versionUpdates.subscribe((evt) => {
          switch (evt.type) {
            case 'VERSION_DETECTED':
              break;
            case 'VERSION_READY':
              this.isNewVersionAvailable = true;
              this.applyUpdate();
              break;
            case 'NO_NEW_VERSION_DETECTED':
              break;
            case 'VERSION_INSTALLATION_FAILED':
              console.log(
                `Failed to install app version '${evt.version.hash}': ${evt.error}`
              );
              break;
          }
        });
      }
    }
  }

  applyUpdate(): void {
    let snack = this._snackBar.open(
      "Une mise à jour de l'app est disponible, souhaitez-vous l'installer ?",
      'OK',
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 10000,
        panelClass: 'successSnack',
      }
    );

    snack.afterDismissed().subscribe(() => {});

    snack.onAction().subscribe(() => {
      this.swUpdate
        .activateUpdate()
        .then(() => document.location.reload())
        .catch((error) => console.error('Failed to apply updates:', error));
    });
  }
}
